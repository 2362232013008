import React from 'react';
import { logout } from 'redux/actions/authentication';
import { connect } from 'react-redux';
import FreshChat from 'react-freshchat';
import ErrorPage from './ErrorPage';
import { logError } from '../redux/actions/logs'
import { ENV } from '../config/config'

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		// console.log('ErrorBoundary', error);
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, errorInfo);

		if (!ENV || ENV !== 'DEVELOPMENT')
			this.props.logError({
				email: this.props.email,
				info: {
					mobile: this.props.user.mobile,
					userAgent: navigator.userAgent,
					windowLocation: global.location.href,
					error: error.stack,
				}
			})
		window.location.hash = "error"
		this.setState({
			hasError: true,
		});
	}

	render() {
		return (
			<React.Fragment>
				{this.state.hasError ? <ErrorPage logout={() => {
					this.props.logout();
					window.location = '/'
				}} /> : this.props.children}
				<FreshChat
					token="3644b771-cab6-413e-8707-e816daff474d"
					host="https://wchat.in.freshchat.com"
					onInit={widget => {
						if (this.props.user) {
							widget.user.setProperties({
								email: this.props.email,
								first_name: this.props.user.firstname,
								last_name: this.props.user.lastname,
								phone: this.props.user.mobile,
								utm_campaign: this.props.campaign
							});
						}
					}}
				/>
			</React.Fragment>
		);
		// return this.props.children;
	}
}

function mapStateToProps(state) {
	// let utmCampaign
	// if (state.profile.utmDetails)
	// 	utmCampaign = state.profile.utmDetails.campaign ? state.profile.utmDetails.campaign : 'DIRECT'
	return {
		// utmCampaign,
		user: state.profile?.basic ? state.profile?.basic : {},
		email: state.profile?.email ? state.profile?.email : {},
		campaign: state.onboarding?.utm?.campaign ? state.onboarding?.utm?.campaign : {}
	};
}

export default connect(
	mapStateToProps,
	{ logout, logError }
)(ErrorBoundary);
