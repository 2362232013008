import Routes from '../config/routes'
import { GEOLOCATION_URL } from '../config/config';

export function retry(fn, retriesLeft = 5, interval = 1000) {
    return new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error) => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        // reject('maximum retries exceeded');
                        reject(error);
                        return;
                    }

                    // Passing on "reject" is the important part
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
            });
    });
}

export const authenticateUser = (token, onboarding, basicDetails) => {
    if (token) {
        if (onboarding === null || !onboarding.course) {
            return Routes.OnboardingCourses
        }
        else if (onboarding !== null && onboarding.course && !onboarding.parameters) {
            return Routes.Onboarding
        }
        else if (onboarding !== null && onboarding.parameters && (!basicDetails.mobile || !basicDetails.work_status || basicDetails.work_status === 'NA' || (basicDetails?.verification && !basicDetails?.verification?.mobile))) {
            return Routes.OnboardingProfileDetails
        }
    }
    else {
        return Routes.Login
    }
    return undefined
}

export const checkUserFlowType = (utm) => {
    /* User flow types: 
        1. PREMIUM
        2. DIRECT
        3. FREE
    */

    let usertype = ''

    if (utm && utm?.campaign.toUpperCase() === 'DIRECT')
        usertype = 'DIRECT'
    else if (utm && utm?.campaign.includes('premium') || utm?.source.includes('premium'))
        usertype = 'PREMIUM'
    else
        usertype = 'FREE'

    return usertype
}

export const getCourseProgressAndCurrentStatus = (pathway = {}) => {
    let last_module = null, last_completed_level = null, last_phase = null, k = 0, total_course_contents = 0, completed_course_contents = 0

    let current_status = { phase: '', module: '', level: '', progress: {} }, personalised_mode = null
    const all_phase_names = Object.keys(pathway)

    if (all_phase_names && all_phase_names.length > 0) {
        for (const phaseId in pathway) {
            let total_contents_phase = 0, completed_contents_phase = 0, current_phase = '', completed_levels_phase = 0, locked_levels_phase = 0, unlocked_level_total_time = 0, unlocked_level_completed_time = 0

            for (const [modId, moduleData] of Object.entries(pathway[phaseId])) {
                let j = false, lev_completed = 0, idx = 0, total_contents_module = 0, completed_contents_module = 0

                for (const levId in moduleData) {
                    if (levId.includes('Level')) {

                        total_contents_module += moduleData[levId].content.length
                        total_contents_phase += moduleData[levId].content.length

                        if (idx === 0) {
                            moduleData['number'] = moduleData[levId].module
                            idx++
                        }

                        if (moduleData[levId].completed === true) {
                            moduleData['completed'] = true
                            lev_completed++
                            completed_levels_phase++
                            completed_contents_module += moduleData[levId].content.length
                            completed_contents_phase += moduleData[levId].content.length
                            last_completed_level = levId
                            last_module = modId
                            last_phase = phaseId
                            // i = true
                        }

                        if (moduleData[levId].locked === true) {
                            locked_levels_phase++
                        }

                        if (moduleData[levId].locked === false && !j) {
                            moduleData['locked'] = false
                            j = true
                        }

                        if (moduleData[levId].completed === false && moduleData[levId].locked === false) {
                            let completed_contents_level = 0
                            // course_completed = false;
                            completed_contents_level = moduleData[levId]['content'].filter(x => x.activity && x.activity.marked_complete === true).length
                            completed_contents_module += completed_contents_level
                            completed_contents_phase += completed_contents_level
                            moduleData['completed'] = false

                            moduleData[levId].content.forEach(con => {
                                if (con.activity && con.activity.marked_complete === true)
                                    unlocked_level_completed_time += con.meta.duration

                                unlocked_level_total_time += con.meta.duration
                            })

                            if (k === 0) {
                                current_status['progress']['level'] = Math.round(completed_contents_level / moduleData[levId].content.length * 100)
                                current_phase = phaseId
                                current_status['phase'] = phaseId
                                current_status['module'] = modId
                                current_status['level'] = levId
                            }
                            k++
                        }

                        // if (i && j && k) break

                    }
                }

                if (k === 1) current_status['progress']['module'] = Math.round(completed_contents_module / total_contents_module * 100)
                moduleData['levels_completed'] = lev_completed

                if (moduleData.name === undefined) moduleData['name'] = "Digital Marketing Foundation"

                if (moduleData.completed === undefined) moduleData['completed'] = false

                if (moduleData.locked === undefined) moduleData['locked'] = true
            }

            if (current_phase === phaseId) {
                personalised_mode = {
                    locked_levels: locked_levels_phase,
                    completed_levels: completed_levels_phase++,
                    unlocked_levels_total_time: unlocked_level_total_time,
                    unlocked_levels_completed_time: unlocked_level_completed_time
                }
                current_status['progress']['phase'] = Math.round(completed_contents_phase / total_contents_phase * 100)
            }

            total_course_contents += total_contents_phase;
            completed_course_contents += completed_contents_phase;

            if (phaseId === all_phase_names[all_phase_names.length - 1]) {
                current_status.progress['course'] = Math.round((completed_course_contents / total_course_contents) * 100)
            }

        }

        if (current_status.phase.length === 0 && current_status.module.length === 0 && current_status.level.length === 0) {
            current_status = { phase: last_phase, module: last_module, level: last_completed_level, progress: { phase: 100, module: 100, level: 100 } }
        }
    }

    // RETURN
    return { current_status, personalised_mode }
}

export const objectIsEmpty = (obj) => {
    return obj && Object.keys(obj).length === 0
}

export const getUserLocation = async () => {
    //Get user's location info from ip
    try {
        let response = await fetch(GEOLOCATION_URL)
        // console.log(response.headers.get('X-Ttl'))
        return response.json()
    } catch (err) {
        console.log(err)
    }
}
