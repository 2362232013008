import { axiosNoAuth } from 'config/axios-instances';
import { API_AUTH_URL } from 'config/config';
import {
	USER_TOKEN,
	STORE_REFERRAL,
	LOGOUT_REQUEST,
} from 'redux/types';
import { Api_url } from 'config/api_url'
import { SET_PROFILE_BASIC_DETAILS, SET_ONBOARDING_DETAILS, SET_COMMUNITY_FLAG } from '../types';

export function loginHandler(data, successCallback, errorCallback) {
	return async function (dispatch) {
		try {
			let response = await axiosNoAuth.post(API_AUTH_URL + Api_url.Auth, data);
			await dispatch({
				type: USER_TOKEN,
				payload: response.data.data.token
			})
			await dispatch({
				type: SET_COMMUNITY_FLAG,
				payload: false
			})
			await dispatch({
				type: SET_PROFILE_BASIC_DETAILS,
				payload: {
					email: response.data.data?.user.email,
					basic: response.data.data?.user?.profile?.basic,
					referral: response.data.data?.user?.referral?.referred_users,
					verification: response.data.data?.user?.profile?.verification,
					created_at: response.data.data?.user.created_at
				}
			})
			if (response.data.data?.pathway)
				await dispatch({
					type: SET_ONBOARDING_DETAILS,
					payload: response.data.data?.pathway
				})

			successCallback && successCallback(response.data.data);
		} catch (e) {
			console.error(e);
			errorCallback && errorCallback(e.response.data.message);
		}
	};
}

export function logout(successCallback, errorCallback) {
	return async function (dispatch) {
		try {
			await dispatch({ type: LOGOUT_REQUEST });
			if (navigator && navigator.serviceWorker && navigator.serviceWorker.messageFn)
				navigator.serviceWorker.removeEventListener('message', navigator.serviceWorker.messageFn)
			// if (window.gestureFn)
			// 	window.removeEventListener('load', window.gestureFn)
		} catch (e) {
			// console.error(e);
			errorCallback && errorCallback(e);
		}
	};
}
