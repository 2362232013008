import { PURGE } from 'redux-persist';
import { SET_PROFILE_BASIC_DETAILS, SET_PROFESSIONAL_DETAILS, GET_PROFESSIONAL_DETAILS, SET_CV_DETAILS, SET_ONE_ACHIEVEMENT, SET_ALL_ACHIEVEMENTS, SET_SKILLS, SET_JOBS, SET_ALL_EDUCATION, GET_ACADEMIC_DETAILS, SET_ONE_CERTIFICATE, SET_ALL_CERTIFICATE, SET_PROFILE_PICTURE, SET_COVER_PICTURE, SET_LEARNING_PROGRESS } from '../types';

export default (state = { learningProgress: false }, action) => {
	switch (action.type) {
		case PURGE:
			return null

		case SET_PROFILE_BASIC_DETAILS:
			let tempState = { ...state }
			if (action.payload.created_at)
				tempState['created_at'] = action.payload.created_at
			if (action.payload.email)
				tempState['email'] = action.payload.email
			if (action.payload.basic)
				tempState['basic'] = { ...tempState.basic, ...action.payload.basic }
			if (action.payload.links)
				tempState['links'] = { ...tempState.links, ...action.payload.links }
			if (action.payload.referral === 0 ? true : action.payload.referral)
				tempState['referral'] = action.payload.referral
			if (action.payload.verification)
				tempState['verification'] = action.payload.verification
			return tempState

		case SET_PROFILE_PICTURE:
			return {
				...state,
				basic: { ...state.basic, profile_picture: action.payload.basic.profile_picture }
			}

		case SET_COVER_PICTURE:
			return {
				...state,
				basic: { ...state.basic, cover_picture: action.payload.basic.cover_picture }
			}

		case SET_PROFESSIONAL_DETAILS:
			if (action.payload.jobs)
				return {
					...state,
					professional: {
						...state.professional,
						jobs: [...action.payload.jobs]
					}
				}
			if (action.payload.skills)
				return {
					...state,
					professional: {
						...state.professional,
						skills: [...state.professional.skills, action.payload.skills]
					}
				}

		case GET_PROFESSIONAL_DETAILS:
			return {
				...state,
				...action.payload
			}

		case GET_ACADEMIC_DETAILS:
			return {
				...state,
				...action.payload
			}

		case SET_JOBS:
			return {
				...state,
				professional: {
					...state.professional,
					jobs: action.payload
				}
			}

		case SET_SKILLS:
			return {
				...state,
				professional: {
					...state.professional,
					skills: action.payload
				}
			}

		case SET_CV_DETAILS:
			if (action.payload.objective)
				return {
					...state,
					cv: {
						...state.cv,
						objective: action.payload.objective
					}
				}
			if (action.payload.cover_letter || action.payload.cover_letter === '')
				return {
					...state,
					cv: {
						...state.cv,
						cover_letter: action.payload.cover_letter
					}
				}

		// case SET_ONE_ACHIEVEMENT:
		// 	return {
		// 		...state,
		// 		achievements: state.achievements ? [...state.achievements, action.payload] : [action.payload]
		// 	}

		case SET_ALL_ACHIEVEMENTS:
			return {
				...state,
				achievements: action.payload
			}

		// case SET_ONE_EDUCATION:
		// 	return {
		// 		...state,
		// 		education: state.education ? [...state.education, action.payload] : [action.payload]
		// 	}

		case SET_ALL_EDUCATION:
			return {
				...state,
				education: action.payload
			}

		// case SET_ONE_CERTIFICATE:
		// 	return {
		// 		...state,
		// 		certificates: state.certificate ? [...state.certificate, action.payload] : [action.payload]
		// 	}

		case SET_ALL_CERTIFICATE:
			return {
				...state,
				certificates: action.payload
			}
		case SET_LEARNING_PROGRESS:
			return {
				...state,
				learningProgress: action.payload
			}
		default:
			return state;
	}
};
