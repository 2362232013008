export const Api_url = {


	// 	-----------	AUTHENTICATION	-----------
	Auth: '/auth/',

	// 	-----------	ACTIVITY	-----------
	MarkAsComplete: '/mark-complete/',
	BlockContent: '/block/',
	ContentLike: '/like/',
	ContentDislike: '/dislike/',
	LearningSummary: '/profile-learning-summary/',
	QuizActivity: '/profile-activity/',
	LearningSummaryPercentage: '/profile-learning-summary-percentages/',
	LearnQDriveQ: '/profile-learnq-driveq/',
	ShareToLinkedin: '/linkedin-share/',
	ShareToTwitter: '/twitter-share/',
	SubscribeYoutubeChannel: '/youtube-share/',
	googleReview: '/google-review-share/',
	GetLockedModalSpecs: '/exit-intent/',

	//get bookmarks
	Bookmark: '/bookmark/',

	// 	-----------	ONBOARDING	-----------
	GetCourseConfiguration: '/courses/',
	SubmitParameters: '/store/parameters/',
	SubmitProfile: '/store/profile/',

	// 	---------	PROFILE	------------
	// getters ->
	GetBasicInfo: '/basic-info/',
	GetProfessionalDetails: '/professional-details/',
	GetAcademicDetails: '/academic-details/',
	GetRefferalDetails: '/referral/',
	// setters ->
	SetBasicInfo: '/basic-info/set/',
	SetProfileLinks: '/profile-links/set/',
	SetProfessionalSkills: '/professional/skills/set/',
	SetProfessionalObjective: '/professional/objective/set/',
	SetCoverLetter: '/cover-letter/set/',
	SetCoverPic: '/cover-picture/set/',
	SetProfilePic: '/profile-picture/set/',
	AddJob: '/professional/job/add/',
	AddAchievement: '/achievement/add/',
	AddEducation: '/education/add/',
	AddCertificate: '/certificate/add/',
	ExtendCourseAccess: '/extend-days/',
	// edit ->
	EditUserJob: '/professional/job/edit/',
	EditAchievement: '/achievement/edit/',
	EditEducation: '/education/edit/',
	EditCertificate: '/certificate/edit/',
	EditWalkthrough: '/walkthrough',
	// cv cert
	GetCertificate: '/cn-certificate/',
	GetCv: '/cn-cv/',

	GetModuleName: '/goal-learning-pathway/module/',
	ExtendLockedCourse: '/user/extend/course/',
	// SaveProfile: '/user-activity/post/'

	// ------------		SYLLABUS	----------------
	// getters ->
	GetSyllabusData: '/dashboard/',
	GetLevelData: '/search/pathway/',
	GetCollectionOfData: '/search/id/all/',
	GetRecommendations: '/get/recommendations/',

	// get dashboard
	UserActivity: '/user-activity/',
	storeUserTokenInFirebase: '/add-token/',
	GetGraphData: '/get/graph-data/',
	GetFailedQuestion: '/wrong-questions/',
	GetContentForQuestion: '/get/related-content/',

	//get jobs
	GetJobs: '/getjobs/',

	//Quiz
	GetQuizAuthToken: '/get/token/',
	CheckAnswer: '/evaluate/answer/',
	GetResults: '/user/get/quizlist/',
	GetQuizQuestion: '/get/question/',
	GetPreviousQuizResponse: '/get/prev-response',

	GetAvgScore: '/quiz/score/avg/',

	//day week plan
	CheckIfDayDone: '/dayweek/check-day/',
	CheckIfWeekDone: '/dayweek/check-week/',
	EditDayWeekTime: '/dayweek/edit-time/',
	EditDayWeekPlan: '/dayweek/edit-plan/',
	ToggleDayPlanMode: '/dayweek/enable-plan/',
	ResetCounter: '/dayweek/reset-plan/',

	//user job experience
	SaveSocialProfile: '/user/save/profile/',
	AddUserEducation: '/user/addUserEducation/',
	EditUserEducation: '/user-activity/edit/education/',
	AddUserCertification: '/user/addUserCertificate/',
	EditUserCertification: '/user-activity/edit/certificate/',
	UpdateBasicInfo: '/user/update/information/',
	SaveUserSkills: '/user/update/skill/',
	saveUserKind: '/user/userKind/',
	SaveCVDetails: '/user/save/cv/',
	SaveUserAchievement: '/user/addAchievement/',
	EditUserAchievement: '/user/edit/achievement/',
	GetUserActivity: '/user-activity/getActivity/',
	SaveWorkPreference: '/user/save/workPreference/',
	GetDriveQ: '/user/profile/driveQ/',
	GetLearnQ: '/user/profile/learnQ/',
	GetQuizResponse: '/user/get/quizResponse/',
	RateWorkPreference: '/user/post/work/preference/',
	SetWorkPreference: '/work-preference/set/',
	GetWorkPreference: '/work-preference/',
	GetUserTimeLeft: '/user/get/workTime/',
	LockingSystem: '/user/lock/time/left/',
	DeductFromReferredDiscount: '/user/remove/discount/',
	FreeSubscribe: '/offer/subscribe/',
	CourseRating: '/user/rate/course/',
	SaveTimeLines: '/user/timelines/',
	SendEmail: '/user/send/email/',

	// log urls
	LogPricingPageClickCount: '/pricing-page-visit/',
	LogPlanPaymentCancelled: '/plan-payment-cancel/',
	LogError: '/error/',
	NewUserUpgrade: '/new-verson-user/',
	SendComboPremiumClicks: '/plan-click-email/',
	NewUserSignUp: '/new-user-signup/',
	GetRandomNewUser: '/get-new-users/',

	// opt-out
	ValidateOptOutLink: '/optout/validate/',
	SubmitOptOutResponse: '/optout/unsubscribe/',

	//pricing page
	GetPricing: '/get/pricing-plans/',
	GetUserPricingPlan: '/get/pricing-plans/user',
	// GetPricing: '/get/pricing/',

	// community
	CreateCommunityPost: '/user-post/create/',
	GetCategories: '/categories/',
	CommunityAuthenticate: '/auth/',
	GetAllPost: '/post/all/',

	//Get Course Parameters List
	GetParametersValue: '/courses/get/params/',

	//Payment Links
	GetLinkDetails: '/link/details',
	EmiEnable: '/enable/emi/',
	GetEmiDetails: '/emi/details/',
	EmiPaymentDone: '/emi/payment/done/',
	GetPlanData: '/get/plan/data/',
	SuccessPayment: '/payment/success/',
	FullPayment: '/payment/booking/',

	//notification urls
	GetNotifications: '/get/notifications/',
	ReadNotification: '/read/notification/',
	deleteNotification: '/delete/notification/',
	deleteAllNotification: '/delete/all/notification/',

	//Report Activity
	ReportActivity: '/report-activity/',

	//Get Content Summary
	GetSummary: '/get/summary',

	//Practical Task
	GetPracticalTask: '/practical-task',
	GetAllPracticalTask: '/practical-task/all',

	//mcq pop quiz
	GetPopQuiz: '/popquiz',
	PopQuizActivity: '/mark-popquiz',
	GetPopQuizActivity: '/get/popquiz-activity',


	//microcourse
	GetMicrocourse: '/get/microcourse/',
	GetMicroCourseQuiz: '/get/mcq-quiz',
	MicroCourseActivity: '/mark-micro-course-item',
	GetMicroCourseActivity: '/get/micro-course-activity',
};
