import React from 'react';
import ReactDOM from 'react-dom';
import AppWrapper from './container/App';
import * as serviceWorker from './serviceWorker';
import { ENV } from './config/config';

ReactDOM.render(<AppWrapper />, document.getElementById('root'));
if (ENV === "production") {
    console.log = function () { };
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
