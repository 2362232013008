import {
	SET_USER_COURSE, SET_ONBOARDING_REDIRECT_TRIGGER, SET_UTM, SET_ONBOARDING_ASPIRATIONS, SET_OTP_RETRIES,
	SET_ONBOARDING_BACKGROUND, SET_ONBOARDING_SPECIALIZATIONS, SET_ONBOARDING_STEP_NO, SET_LOGIN_REDIRECT, SET_ALL_COURSE_CONFIG,
	SET_ONE_COURSE_CONFIG, PARAMETERS_SUBMIT_FLAG, SET_ONBOARDING_DETAILS, SET_LOADER_TRIGGER
} from 'redux/types';
import { PURGE } from 'redux-persist';

export default (state = { loader_trigger: false }, action) => {
	switch (action.type) {
		case PURGE:
			return null

		case SET_USER_COURSE:
			return {
				...state,
				course: { key: action.payload }
			}
		case SET_UTM:
			return {
				...state,
				utm: action.payload
			}
		case SET_ONBOARDING_ASPIRATIONS:
			return {
				...state,
				parameters: {
					...state.parameters,
					...action.payload
				}
			}
		case SET_ONBOARDING_BACKGROUND:
			return {
				...state,
				parameters: {
					...state.parameters,
					...action.payload
				}
			}
		case SET_ONBOARDING_SPECIALIZATIONS:
			return {
				...state,
				parameters: {
					...state.parameters,
					major: action.payload.major,
					minor: action.payload.minor
				}
			}
		case SET_ONBOARDING_REDIRECT_TRIGGER:
			return {
				...state,
				redirect: action.payload
			}
		case SET_ONBOARDING_STEP_NO:
			return {
				...state,
				step: action.payload
			}
		case SET_LOGIN_REDIRECT:
			return {
				...state,
				redirect: action.payload.redirect,
				step: action.payload.step
			}
		case SET_ONE_COURSE_CONFIG:
			return {
				...state,
				courseConfig: action.payload.courseConfig,
				parameters: action.payload.parameters
			}
		case SET_ONBOARDING_DETAILS:
			let tempState = { ...state }
			if (action.payload.onboarding)
				tempState['parameters'] = action.payload.onboarding.parameters
			if (action.payload.utm)
				tempState['utm'] = action.payload.utm
			if (action.payload.course)
				tempState['course'] = { key: action.payload.course.key }

			return tempState
		case SET_ALL_COURSE_CONFIG:
			return {
				...state,
				courseConfig: action.payload
			}
		case PARAMETERS_SUBMIT_FLAG:
			return {
				...state,
				parameters: {
					...state.parameters,
					saved: action.payload
				}
			}
		case SET_OTP_RETRIES:
			return {
				...state,
				otp_retries: action.payload
			}
		case SET_LOADER_TRIGGER:
			return {
				...state, loader_trigger: action.payload
			}
		default:
			return state;
	}
};
