import "./_link-page.scss"
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import {
    getLinkDetails, enableEMIOption, getEMIPaymentLink,
    emiPaymentSuccessful, getPricingPlanData, successPayment, enableFullPayment
} from 'redux/actions/links'
import { connect } from 'react-redux'
import { Desktop, TabletMobile } from 'utils/media-query'
import { toast } from 'react-toastify';
import { RAZORPAY_KEY } from "../../config/config"


class LinkPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            valid: false,
            plan_name: null,
            plan_amount: 0,
            emi_enable: false,
            emi_months: 0,
            course_name: null,
            emi_id: null,
            link_id: null,
            plan_data: null,
            overlay: false,
            email: null,
            full_amount: 0,
            months: 0,
            booking: false,
            current: 0
        }
    }

    componentDidMount = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const linkId = urlParams.get('link_id')
        const emiId = urlParams.get('emi')
        this.setState({ emi_id: emiId, link_id: linkId })

        if (linkId === undefined || linkId === null) {
            this.setState({ valid: false })
            // this.props.history.push("/dashboard")
        }
        if (emiId !== undefined && emiId !== null) {
            this.props.getEMIPaymentLink({
                emi_generated_id: emiId
            }, (data) => {
                if (data) {
                    this.props.getPricingPlanData({ plan_name: data.data.plan_name, course_name: (data.data.course_name).split(' ').join('_') }, (resp) => {
                        this.setState({
                            plan_name: data.data.plan_name, plan_amount: data.data.plan_amount, emi_enable: true,
                            course_name: data.data.course_name, plan_data: resp.data[0].plan_details, booking: false, full_amount: data.data.full_amount,
                            months: data.data.total_emi, current: data.data.emi_number
                        })
                    })
                }

            })
        }
        else {
            this.props.getLinkDetails({
                link: linkId
            }, (data) => {
                if (data) {
                    this.props.getPricingPlanData({ plan_name: data.data.plan_name, course_name: (data.data.course_name).split(' ').join('_') }, (resp) => {
                        this.setState({
                            plan_name: data.data.plan_name, plan_amount: data.data.plan_amount,
                            emi_enable: data.data.emi_enable, emi_months: data.data.emi_months, course_name: data.data.course_name,
                            plan_data: resp.data[0].plan_details, booking: data.data.booking, full_amount: data.data.full_amount
                        })
                    })
                }
            })
        }
    }

    getBooking = () => {
        if (this.state.booking === true)
            return ' - BOOKING AMOUNT'
        else
            return ''
    }

    getMoney = (price) => {
        // if (this.state.emi_enable === false)
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // else {
        //     let amount = Math.ceil(price / this.state.emi_months).toFixed(2)
        //     return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // }
    }

    getMoneyRazorpay = (price) => {
        // if (this.state.emi_enable === false)
        return price
        // else {
        //     let amount = Math.ceil(price / this.state.emi_months).toFixed(2)
        //     return amount
        // }
    }

    openRazorPay = async (price, item) => {
        let options = {
            "key": RAZORPAY_KEY,
            "amount": price * 100,
            "name": "BeNinja Solutions Private Limited",
            "description": item,
            "prefill": {
                "email": this.state.email,
                "contact": this.props.userProfile?.basic?.mobile
            },
            "notes": {
                "address": "note value"
            },
            "theme": {
                "color": "#528FF0"
            },
            "modal": {
                "ondismiss": () => {
                    toast.error("Complete payment and watch your career prospects increase upto 10x!")
                    this.props.email !== undefined && this.props.logPlanPaymentCancelled({
                        email: this.props.userProfile.email,
                        planName: item
                    })
                }
            },
            "handler": (response) => {
                if (response.razorpay_payment_id) {
                    toast.success("Payment Successfull!")
                    this.props.successPayment({
                        razorpay_payment_id: response.razorpay_payment_id,
                        email: this.props.userProfile?.email ? this.props.userProfile.email : this.state.email,
                        course_name: this.state.course_name,
                        plan_name: item,
                        paid_amount: price
                    })
                    if (this.state.booking === true) {
                        if (this.state.emi_enable === true) {
                            this.props.enableEMIOption({
                                emi_months: this.state.emi_months - 1,
                                plan_amount: this.state.plan_amount,
                                plan_name: this.state.plan_name,
                                course_name: this.state.course_name,
                                user_email: this.props.userProfile?.email ? this.props.userProfile.email : this.state.email,
                                paid_amount: price,
                                full_amount: this.state.full_amount,
                                booking: this.state.booking,
                                emi_enable: true
                            })
                        }
                        else {
                            this.props.enableFullPayment({
                                plan_amount: this.state.full_amount,
                                plan_name: this.state.plan_name,
                                course_name: this.state.course_name,
                                user_email: this.props.userProfile?.email ? this.props.userProfile.email : this.state.email,
                                paid_amount: price
                            })
                        }
                    }
                    else {
                        if (this.state.emi_enable === true) {
                            this.props.enableEMIOption({
                                emi_months: this.state.emi_months - 1,
                                plan_amount: this.state.plan_amount,
                                plan_name: this.state.plan_name,
                                course_name: this.state.course_name,
                                user_email: this.props.userProfile?.email ? this.props.userProfile.email : this.state.email,
                                paid_amount: price,
                                booking: false,
                                emi_enable: true,
                                full_amount: this.state.full_amount
                            })
                        }
                    }
                    if (this.state.emi_id !== undefined && this.state.emi_id !== null) {
                        this.props.emiPaymentSuccessful({
                            emi_generated_id: this.state.emi_id,
                            course_name: this.state.course_name
                        })
                    }
                }
                else {
                    toast.error("Complete payment and watch your career prospects increase upto 10x!")
                    this.props.email !== undefined && this.props.logPlanPaymentCancelled({
                        email: this.props.userProfile.email,
                        planName: item
                    })
                }
                // this.props.getStageDetails()
                // this.props.history.push('/dashboard')
            },
        };

        let rzp = new window.Razorpay(options);
        rzp.open();
    }

    openCheckout = async (price, item) => {
        if (this.props.userProfile?.email) {
            let options = {
                "key": RAZORPAY_KEY,
                "amount": price * 100,
                "name": "BeNinja Solutions Private Limited",
                "description": item,
                "prefill": {
                    "email": this.props.userProfile?.email,
                    "contact": this.props.userProfile?.basic?.mobile
                },
                "notes": {
                    "address": "note value"
                },
                "theme": {
                    "color": "#528FF0"
                },
                "modal": {
                    "ondismiss": () => {
                        toast.error("Complete payment and watch your career prospects increase upto 10x!")
                        this.props.email !== undefined && this.props.logPlanPaymentCancelled({
                            email: this.props.userProfile.email,
                            planName: item
                        })
                    }
                },
                "handler": (response) => {
                    if (response.razorpay_payment_id) {
                        toast.success("Payment Successfull!")
                        this.props.successPayment({
                            razorpay_payment_id: response.razorpay_payment_id,
                            email: this.props.userProfile?.email ? this.props.userProfile.email : this.state.email,
                            course_name: this.state.course_name,
                            plan_name: item,
                            paid_amount: parseInt(price)
                        })
                        if (this.state.booking === true) {
                            if (this.state.emi_enable === true) {
                                this.props.enableEMIOption({
                                    emi_months: this.state.emi_months,
                                    plan_amount: this.state.plan_amount,
                                    plan_name: this.state.plan_name,
                                    course_name: this.state.course_name,
                                    user_email: this.props.userProfile?.email ? this.props.userProfile.email : this.state.email,
                                    paid_amount: price,
                                    full_amount: this.state.full_amount,
                                    booking: this.state.booking
                                })
                            }
                            else {
                                this.props.enableFullPayment({
                                    plan_amount: this.state.full_amount,
                                    plan_name: this.state.plan_name,
                                    course_name: this.state.course_name,
                                    user_email: this.props.userProfile?.email ? this.props.userProfile.email : this.state.email,
                                    paid_amount: price
                                })
                            }
                        }
                        else {
                            if (this.state.emi_enable === true) {
                                this.props.enableEMIOption({
                                    emi_months: this.state.emi_months - 1,
                                    plan_amount: this.state.plan_amount,
                                    plan_name: this.state.plan_name,
                                    course_name: this.state.course_name,
                                    user_email: this.props.userProfile?.email ? this.props.userProfile.email : this.state.email,
                                    paid_amount: price,
                                    booking: false
                                })
                            }
                        }
                        if (this.state.emi_id !== undefined && this.state.emi_id !== null) {
                            this.props.emiPaymentSuccessful({
                                emi_generated_id: this.state.emi_id,
                                course_name: this.state.course_name,
                            })
                        }
                    }
                    else {
                        toast.error("Complete payment and watch your career prospects increase upto 10x!")
                        this.props.email !== undefined && this.props.logPlanPaymentCancelled({
                            email: this.props.userProfile.email,
                            planName: item
                        })
                    }
                    // this.props.getStageDetails()
                    // this.props.history.push('/dashboard')
                },
            };

            let rzp = new window.Razorpay(options);
            rzp.open();
        } else {
            this.setState({ overlay: true })
        }
    }

    getPlanName = (planName) => {
        if (planName)
            return planName.split(" ")[0] + `'s PLAN`
    }

    getEmailText = (value) => {
        this.setState({ email: value })
    }

    confirmEmail = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(String(this.state.email).toLowerCase())) {
            this.setState({ overlay: null })
            this.openRazorPay(this.state.plan_amount, this.state.plan_name)
        }
        else {
            toast.error("Please Enter Valid Email ID")
        }
    }

    getEMIValue = () => {
        if (this.state.booking === false || this.state.booking === undefined)
            if (this.state.emi_months !== 0)
                return ('(EMI - ' + this.state.emi_months + ' Months)')
            else
                return ('(EMI - ' + this.state.months + ' Months)')
        else return ''
    }

    render() {
        return (
            <div className="link-page-container">
                {this.state.overlay === true &&
                    <div id="overlay">
                        <p>Please Enter Your Registered Email ID</p>
                        <p className="cross-btn" onClick={() => this.setState({ overlay: false })}>X</p>
                        <div className="overlay-input">
                            <input type="text" className="input-text-box" onInput={(e) => this.getEmailText(e.target.value)} />
                            <button className="purchase-btn email-btn" onClick={() => this.confirmEmail()}>Confirm</button>
                        </div>
                    </div>
                }
                <div className="link-inner-container">
                    <div className="details-container">
                        <img className="logo-img" src="/img/careerninja-wo-tagline.png" />
                        <div className="row payment-details">
                            <div className="col-md-4 image-column-side">
                                <img alt="" src="/img/CN_Mascot.png" />
                            </div>
                            <div className="col-md-7 details-col">
                                <div className="details-div">
                                    <Desktop>
                                        <p className="details-div-head">{this.state.course_name}</p>
                                        <hr className="head-underline" />
                                        <p className="details-div-plan-name">{this.state.plan_name} : HIGHLIGHTS</p>
                                    </Desktop>
                                    <TabletMobile>
                                        <p className="details-div-plan-name plan-name-mobile">{this.state.course_name} : {this.state.plan_name}</p>
                                    </TabletMobile>
                                    <div className="plan-details">
                                        <div className="row">
                                            {this.state.plan_data && (
                                                this.state.plan_data.map((plan, index) => {
                                                    return (
                                                        <div className="plan-details-points col-md-6" key={index}>
                                                            <img className="tick-mark" src="/img/tick-mark.png" /> <p>{plan.main}</p>
                                                        </div>
                                                    )
                                                })
                                            )
                                            }
                                        </div>
                                    </div>
                                    <TabletMobile>
                                        <hr />
                                        <div className="row purchase-row">
                                            <div className="col-md-8 col-7 plan-text">
                                                <p>{this.getPlanName(this.state.plan_name)} {this.state.emi_enable === true ? '(EMI)' : ''}</p>
                                                <hr />
                                                <p className="purchase-money">₹ {this.getMoney(this.state.plan_amount)}</p>
                                            </div>
                                            <div className="col-md-4 col-5 purchase-right">
                                                <button className="purchase-btn" onClick={() => this.openCheckout(this.getMoneyRazorpay(this.state.plan_amount), this.state.plan_name)}>
                                                    purchase
                                                </button>
                                            </div>
                                        </div>
                                    </TabletMobile>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Desktop>
                        <div className="purchase-container">
                            <div className="row purchase-row">
                                {this.state.emi_enable === true ?
                                    <div className="col-md-6 plan-text">
                                        {this.getPlanName(this.state.plan_name) + this.getBooking()} {this.getEMIValue()} <br />
                                        {this.state.booking === false &&
                                            <p className="installment-text">(Installment - {this.state.current})</p>
                                        }
                                    </div>
                                    : <div className="col-md-6 plan-text">
                                        {this.getPlanName(this.state.plan_name) + this.getBooking()}
                                    </div>}
                                <div className="col-md-6 purchase-right">
                                    <div className="col-md-8">
                                        <p className="purchase-money">₹ {this.getMoney(this.state.plan_amount)}</p>
                                    </div>
                                    <button className="purchase-btn" onClick={() => this.openCheckout(this.getMoneyRazorpay(this.state.plan_amount), this.state.plan_name)}>
                                        purchase
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Desktop>
                </div>
                <Desktop>
                    <div className="terms-condition-container">
                        <div className="row purchase-row">
                            <div className="col-md-4">
                                <p className="terms-text">Contact Us: <span>9870302517</span></p>
                            </div>
                            <div className="col-md-4">
                                <p className="terms-text"> Share This: </p>
                            </div>
                            <div className="col-md-4">
                                <p className="terms-text">Terms & Conditions: </p>
                            </div>
                        </div>
                    </div>
                </Desktop>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        userProfile: state.profile ? state.profile : undefined,
    }
}

// export default Profile;
export default withRouter(connect(
    mapStateToProps,
    {
        getLinkDetails, enableEMIOption, getEMIPaymentLink, emiPaymentSuccessful,
        getPricingPlanData, successPayment, enableFullPayment
    }
)(LinkPage))
