import React from 'react';
import '../styles/loader.scss';
import { ImageLoader } from './images';

export default class ErrorPage extends React.Component {
	render() {
		return (
			<div className='error-page'>
				<ImageLoader src={'errorGif'} alt='Error' className='error-gif' />
				<div className="error-msg">
					<p>
						Please logout and log back in to continue and leave a message telling us
						what went wrong.
					</p>
					<p> Thank you!</p>
					<button style={{ marginTop: '20px' }} className="btn-fill" onClick={async () => this.props.logout()}					>
						Logout
					</button>
				</div>
			</div>
		);
	}
}
