const Constants = {
	desktop_min: 992,
	tablet_mobile_max: 991,
	tablet_min: 768,
	mobile_max: 767,
	minor_goal_length: 2,
	newbie: 'Newbie',
	beginner: 'Beginner',
	intermediate: 'Intermediate',
	expert: 'Expert',
	strong_cyan: '#25bba2',
	box_shadow: '0 2px 10px 2px rgba(191, 191, 191, 0.4)',
	white_color: '#fff',
	landing_page_date: 'Mon Apr 20 2020 15:13:29 GMT+0530',

	onboardingWorkStatusOptions: [
		{ value: 'NA', label: 'Select' },
		{ value: 'student', label: 'Student' },
		{ value: 'employed', label: 'Employed' },
		{ value: 'unemployed', label: 'Unemployed' },
		{ value: 'fresher', label: 'Fresher' },
		{ value: 'freelancer', label: 'Freelancer' },
		{ value: 'business', label: 'Business' },
	],
	onboardingProfilePath: '/profiledetails',
	onboardingSpecializationsPath: '/specializations',
	onboardingStagePath: '/stage',
	gender: ['Male', 'Female', 'Other'],
	cities: [
		'Mumbai',
		'Bangalore',
		'Chennai',
		'New Delhi',
		'Kochi',
		'Vizag',
		'Hyderabad',
		'Kolkata',
		'Ahmedabad',
		'Pune',
		'Jaipur',
		'Surat',
		'Lucknow',
		'Chandigarh',
		'Kanpur',
		'Agra',
		'Bhopal',
		'Indore',
		'Patna',
		'Varanasi',
		'Gurugram',
		'Coimbatore',
		'Nagpur',
		'Bhubaneswar',
		'Noida',
		'Faridabad',
		'Udaipur',
		'Amritsar',
		'Ghaziabad',
		'Thiruvananthapuram',
		'Ludhiana',
		'Vadodara',
		'Nashik',
		'Madurai',
		'Guwahati',
		'Raipur',
		'Jabalpur',
		'Prayagraj',
		'Rajkot',
		'Jodhpur',
		'Dehradun',
		'Aurangabad',
		'Ranchi',
		'Vijayawada',
		'Mangalore',
		'Tiruchirapalli',
		'Navi Mumbai',
		'Jamshedpur',
		'Mysuru',
		'Gwalior',
		'Meerut',
	],
	languages: [
		'Hindi',
		'English',
		'Tamil',
		'Malayalam',
		'Kannada',
		'Telugu',
		'Gujarati',
		'Assamese',
		'Bangla',
		'Bodo',
		'Dogri',
		'Kashmiri',
		'Konkani',
		'Maithili',
		'Manipuri',
		'Marathi',
		'Nepali',
		'Oriya',
		'Punjabi',
		'Santali',
		'Sindhi',
		'Urdu',
		'French',
		'Spanish',
		'Arabic',
		'German',
		'Chineese',
	],
	work_status: ["Student", "Employed", "Unemployed", "Fresher", "Freelancer", "Business"],
	relocate: ['Yes', 'No'],
	job_preference: ['Internship', 'Full-time', 'Part-time'],
	pay_range: ['3-4 LPA', '4-5 LPA', '5-6 LPA', '6-8 LPA', '8-10 LPA'],
	work_preference: ["Pay", "Additional Benefits", "Flexible Hours/Work", "Young Workplace", "Company Brand", "Job Security", "Distance from Home", " Growth Opportunities", "Greater Learning", "Weekend Off"],

	job_search_filters: {
		work_experience: ["0-1 year", "1-2 years", "2-3 years", "3+ years"],
		salary_range: {
			"₹6-7 Lacs": {
				salary_min: 600000,
				salary_max: 700000,
			},
			"₹9-10 Lacs": {
				salary_min: 900000,
				salary_max: 1000000,
			},
			"₹11-15 Lacs": {

				salary_min: 1100000,
				salary_max: 1500000,
			},
			"₹15-20 Lacs":
			{
				salary_min: 1500000,
				salary_max: 2000000,
			},
		},
		job_type: ["Full-Time", "Part-Time", "Freelancer", "Contract"],
		location: ["Mumbai", "Delhi", "Bangalore", "Hyderabad", "Pune", "Chennai", "Agra"],
	},
	max_otp_retries: 3,
	environment: {
		dev: 'DEVELOPMENT',
		localdev: 'LOCAL_DEVELOPMENT'
	},
	//Mixpanel events and properties
	MXP: {
		SIGN_UP: "Sign Up",
		LOG_IN: "Log In",
		LOG_OUT: "Log Out",
		SESSION_END: "Session End",
		SESSION_RESTORED: "Session Restored",
		LANDING_PAGE_VISIT: "Landing Page Visit",
		JOBS_PAGE_VISIT: "Jobs Page Visit",
		SYLLABUS_PAGE_VISIT: "Syllabus Page Visit",
		PRICING_PAGE_VISIT: "Pricing Page Visit",
		ONBOARDING_COMPLETE: "Onboarding Complete",
		QUIZ_ATTEMPT: "Quiz Attempt",
		CONTENT_START: "Content Start",
		CONTENT_END: "Content End",
		COMMUNITY_POST: "Community Post",
		SIDEBAR_CLICK: "Sidebar Click",
		PROFILE_ICON_CLICK: "Profile Icon Click",
		BOOKMARK_ICON_CLICK: "Bookmark Icon Click",
		UPGRADE_TO_PREMIUM_CLICK: "Upgrade To Premium Click",
		PROGRESS_PANEL_CLICK: "Progress Panel Click",
		REFER_ICON_CLICK: "Refer Icon Click",
		LP_SIDEBAR_CLICK: "Lp Sidebar Click",
		PWA_INSTALL_CLICK: "PWA Install Click",
		CV_MODAL_OPEN: "CV Modal Open",
		CV_MODAL_CLOSE: "CV Modal Close",
		CV_MODAL_ENGAGEMENT: "CV Modal Engagement",
		CERT_MODAL_OPEN: "Certificate Modal Open",
		CERT_MODAL_CLOSE: "Certificate Modal Close",
		CERT_MODAL_ENGAGEMENT: "Certificate Modal Engagement",
		COMBO_PLAN_MODAL_OPEN: "Combo Plan Modal Open",
		LOCKED_MODAL_OPEN: "Locked Modal Open",
		LOCKED_MODAL_CLOSE: "Locked Modal Close",
		LOCKED_MODAL_ENGAGEMENT: "Locked Modal Engagement",
		SUMMARY_PLAYER_OPEN: "Summary Player Open",
		SUMMARY_PLAYER_CLOSE: "Summary Player Close",
		PSLP_PAGE_VISIT: "PSLP Page Visit",
		PSLP_CTA_CLICK: "PSLP CTA Click",
		ONBOARDING_STEP_COURSES: "Onboarding Step Courses",
		ONBOARDING_STEP_ASPIRATIONS: "Onboarding Step Aspirations",
		ONBOARDING_STEP_BACKGROUND: "Onboarding Step Background",
		ONBOARDING_STEP_SPECIALIZATION: "Onboarding Step Specialization",
		ONBOARDING_STEP_PROFILE_DETAILS: "Onboarding Step Profile Details",
		MCQ_POP_QUIZ_START: "MCQ Pop Quiz Start",
		MCQ_POP_QUIZ_COMPLETE: "MCQ Pop Quiz Complete",
		MCQ_POP_QUIZ_SKIP: "MCQ Pop Quiz Skip",
		REFERRAL_WALKTHROUGH_OPEN: "Refferal Walkthrough Open",
		REFERRAL_WALKTHROUGH_CLOSE: "Referral Walkthrough Close",
		REFERRAL_WALKTHROUGH_COMPLETE: "Referral Walkthrough Complete",
		POP_QUIZ_START: "Pop Quiz Start",
		POP_QUIZ_SUBMIT: "Pop Quiz Submit",
		POP_QUIZ_SKIP: "Pop Quiz Skip",
		FIVE_DAY_CTA_CLICK: "5Day Journey CTA Click",
		FIVE_DAY_START: "5Day Journey Start",
		FIVE_DAY_END: "5Day Journey End",
		INFINITE_SYLLABUS_VISIT: "Infinite Syllabus Page Visit",
	},
	//Walkthrough Constants 
};

export default Constants;


/**
 *
Activation:
FIRST_CONTENT
FIRST_QUIZ
FIVE_DAY_CONSUMPTION (# articles, videos)

Feature Usage:
QUIZ_ATTEMPT (status fail/pass)
CONTENT_START (id, type)
CONTENT_END (id, type)
ONBOARDING_COMPLETE
LANDING_PAGE_VISIT
DASHBOARD_CLICK (coming up, syllabus icon, upgrade, jobs)
PROFILE_SIDEBAR_CLICK (icon, profile_option, bookmarks_option,  refer_friend_option)

Other Events:
LOG_IN
LOG_OUT
SESSION_START
SESSION_END
SESSION_RESTORED
 */