import React from 'react';
import 'styles/loader.scss';
import { ImageLoader } from '../utils/images';

export default class Loader extends React.Component {
	render() {
		return (
			<div className="loader-main-div">
				<ImageLoader src="rocketLoader" alt='' className='loader-image' />
				<p className="loader-text">Your Career Is About To Take Off!</p>
			</div>
		);
	}
}
