import { PURGE } from 'redux-persist';
import { PREV_RESPONSE, RESET_PLAYER, SET_COMPLETED, SET_CURRENT_CONTENT, SET_FRAME, SET_LEVEL_REFRESH, SET_MODULES, SET_PARENT, SET_RELATED_CONTENT, SHOW_POP, SET_TIME_PLAYED, SET_CONTENT_SUMMARY, SET_PREV_TASK_RESPONSE } from '../types';

export default (state = { relatedContents: [], showPopQuiz: false, frame: true, completed: [], refreshLevelPage: false, currentlyPlaying: {}, prevResponse: [] }, action) => {
    switch (action.type) {
        case PURGE:
            return null;
        case SET_CURRENT_CONTENT:
            return {
                ...state,
                currentlyPlaying: action.payload,
                frame: true,
                showPopQuiz: false,
                relatedContents: [],
            }
        case SET_RELATED_CONTENT:
            return {
                ...state,
                relatedContents: action.payload
            }
        case SHOW_POP:
            return {
                ...state,
                showPopQuiz: action.payload
            }
        case SET_FRAME:
            return {
                ...state,
                frame: action.payload
            }
        case SET_COMPLETED:
            return {
                ...state,
                completed: [...state.completed, action.payload]
            }
        case RESET_PLAYER: {
            return {
                relatedContents: [],
                showPopQuiz: false,
                frame: true,
                completed: [],
                prev_response: state.prev_response,
            }
        }
        case SET_MODULES: {
            return {
                ...state,
                modules: action.payload
            }
        }
        case SET_PARENT:
            return {
                ...state,
                parent: action.payload
            }
        case SET_LEVEL_REFRESH:
            return {
                ...state,
                refreshLevelPage: action.payload
            }
        case PREV_RESPONSE:
            return {
                ...state,
                prev_response: {
                    ...state.prev_response,
                    [action.payload.id]: action.payload
                },
            }
        case SET_TIME_PLAYED:
            return {
                ...state,
                currentlyPlaying: {
                    ...state.currentlyPlaying,
                    videoTimePlayed: action.payload
                }
            }
        case SET_CONTENT_SUMMARY:
            const customSort = (a, b) => {
                return (Number(a.split("/").pop().split(".")[0]) - Number(b.split("/").pop().split(".")[0]))
            }
            return {
                ...state,
                currentlyPlaying: {
                    ...state.currentlyPlaying,
                    contentSummary: action.payload.sort(customSort)
                }
            }
        case SET_PREV_TASK_RESPONSE:
            return {
                ...state,
                prevTaskResponse: action.payload
            }
        default:
            return state
    }
}