import { SET_CURRENT_FIVE_DAY_CONTENT, SET_MARKED_ITEMS, SET_SINGLE_MARKED_ITEM } from '../types';

export default (
	state = {
		currentFiveDayContent: {},
		markedItems: {},
	},
	action
) => {
	switch (action.type) {
		case SET_CURRENT_FIVE_DAY_CONTENT:
			return {
				...state,
				currentFiveDayContent: action.payload,
			};
		case SET_MARKED_ITEMS:
			return {
				...state,
				markedItems: action.payload,
			};
		case SET_SINGLE_MARKED_ITEM:
			const markedItem = action.payload;
			return {
				...state,
				markedItems: { ...state.markedItems, ...markedItem },
			};

		default:
			return state;
	}
};
