import { axiosAuth, axiosNoAuth } from 'config/axios-instances'
import { API_LOGS_URL } from 'config/config'
import { Api_url } from 'config/api_url'

export function logPricingPageClickCount(data, successCallback, errorCallback) {
    return async function (dispatch) {
        try {
            const response = await axiosAuth.post(API_LOGS_URL + Api_url.LogPricingPageClickCount, data)
            successCallback && successCallback(response.data.data)
        } catch (e) {
            console.error(e)
            errorCallback && errorCallback(e.response.data.message)
        }
    }
}

export function getRandomNewUser(successCallback, errorCallback) {
    return async function (dispatch) {
        try {
            const response = await axiosAuth.get(API_LOGS_URL + Api_url.GetRandomNewUser)
            successCallback && successCallback(response.data.data)
        } catch (e) {
            console.error(e)
            errorCallback && errorCallback(e.response.data.message)
        }
    }
}

export function logPlanPaymentCancelled(successCallback, errorCallback) {
    return async function () {
        try {
            await axiosNoAuth.get(API_LOGS_URL + Api_url.LogPlanPaymentCancelled)
            successCallback && successCallback()
        } catch (e) {
            console.error(e)
            errorCallback && errorCallback(e.response.data.message)
        }
    }
}

export function newUserLog(successCallback, errorCallback) {
    return async function () {
        try {
            await axiosAuth.get(API_LOGS_URL + Api_url.NewUserSignUp)
            successCallback && successCallback()
        } catch (e) {
            console.error(e)
            errorCallback && errorCallback(e.response.data.message)
        }
    }
}

export function logError(data, successCallback, errorCallback) {
    return async function () {
        try {
            await axiosAuth.post(API_LOGS_URL + Api_url.LogError, data)
            successCallback && successCallback()
        } catch (e) {
            console.error(e)
            errorCallback && errorCallback(e.response.data.message)
        }
    }
}

export function sendEmail(data, successCallback, errorCallback) {
    return async function () {
        try {
            await axiosAuth.post(API_LOGS_URL + Api_url.SendComboPremiumClicks, data)
            successCallback && successCallback()
        } catch (e) {
            console.error(e)
            errorCallback && errorCallback(e.response.data.message)
        }
    }
}

