import { GET_JOBS, RESET_JOBS } from '../types'

export default (state = null, action) => {
    switch (action.type) {
        case GET_JOBS:
            return action.payload
        default:
            return state;
    }
};
