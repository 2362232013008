import { SET_EXIT_INTENT, SET_LINKEDIN_SHARE_STATUS, SET_MODAL_TYPE, SET_TRIAL_MODE, SET_TWITTER_SHARE_STATUS, SET_GOOGLE_REVIEW_SHARE_STATUS, SET_YOUTUBE_SHARE_STATUS } from "../types";

export default (state = { modalType: "main", trialMode: "linkedin" }, action) => {
    switch (action.type) {
        case SET_EXIT_INTENT:
            return { ...state, ...action.payload };
        case SET_LINKEDIN_SHARE_STATUS:
            return { ...state, linkedin_share: action.payload }
        case SET_TWITTER_SHARE_STATUS:
            return { ...state, twitter_share: action.payload }
        case SET_YOUTUBE_SHARE_STATUS:
            return { ...state, youtube_share: action.payload }
            case SET_GOOGLE_REVIEW_SHARE_STATUS:
            return { ...state, googleReview_share: action.payload }
        case SET_MODAL_TYPE:
            return { ...state, modalType: action.payload }
        case SET_TRIAL_MODE:
            return { ...state, trialMode: action.payload }
        default:
            return state;
    }
}