// authentication
export const USER_TOKEN = 'USER_TOKEN'
export const STORE_REFERRAL = 'STORE_REFERRAL'

// onboarding
export const SET_USER_COURSE = 'SET_USER_COURSE'
export const SET_UTM = 'SET_UTM'
export const SET_ONBOARDING_ASPIRATIONS = 'SET_ONBOARDING_ASPIRATIONS'
export const SET_ONBOARDING_BACKGROUND = 'SET_ONBOARDING_BACKGROUND'
export const SET_ONBOARDING_SPECIALIZATIONS = 'SET_ONBOARDING_SPECIALIZATIONS'
export const SET_ONBOARDING_REDIRECT_TRIGGER = 'SET_ONBOARDING_REDIRECT_TRIGGER'
export const SET_ONBOARDING_STEP_NO = 'SET_ONBOARDING_STEP_NO'
export const SET_LOGIN_REDIRECT = 'SET_LOGIN_REDIRECT'
export const SET_ALL_COURSE_CONFIG = 'SET_ALL_COURSE_CONFIG'
export const SET_ONE_COURSE_CONFIG = 'SET_ONE_COURSE_CONFIG'
export const PARAMETERS_SUBMIT_FLAG = 'PARAMETERS_SUBMIT_FLAG'
export const SET_ONBOARDING_DETAILS = 'SET_ONBOARDING_DETAILS'
export const SET_UTM_COURSE = 'SET_UTM_COURSE'
export const SET_OTP_RETRIES = 'SET_OTP_RETRIES'
export const SET_LOADER_TRIGGER = 'SET_LOADER_TRIGGER'

// profile
export const GET_PROFESSIONAL_DETAILS = 'GET_PROFESSIONAL_DETAILS'
export const GET_ACADEMIC_DETAILS = 'GET_ACADEMIC_DETAILS'
export const SET_PROFILE_BASIC_DETAILS = 'SET_PROFILE_BASIC_DETAILS'
export const SET_PROFILE_EMAIL = 'SET_PROFILE_EMAIL'
export const SET_PROFESSIONAL_DETAILS = 'SET_PROFESSIONAL_DETAILS'
export const SET_CV_DETAILS = 'SET_CV_DETAILS'
export const SET_ONE_ACHIEVEMENT = 'SET_ONE_ACHIEVEMENT'
export const SET_ALL_ACHIEVEMENTS = 'SET_ALL_ACHIEVEMENTS'
export const SET_SKILLS = 'SET_SKILLS'
export const SET_JOBS = 'SET_JOBS'
export const SET_ONE_EDUCATION = 'SET_ONE_EDUCATION'
export const SET_ALL_EDUCATION = 'SET_ALL_EDUCATION'
export const SET_ONE_CERTIFICATE = 'SET_ONE_CERTIFICATE'
export const SET_ALL_CERTIFICATE = 'SET_ALL_CERTIFICATE'
export const TOGGLE_CERT_MODAL = 'TOGGLE_CERT_MODAL'
export const TOGGLE_CV_MODAL = 'TOGGLE_CV_MODAL'
export const GRAPH_DATA = 'GRAPH_DATA'
export const SET_PROFILE_PICTURE = 'SET_PROFILE_PICTURE'
export const SET_COVER_PICTURE = 'SET_COVER_PICTURE'
export const SET_LEARNING_PROGRESS = 'SET_LEARNING_PROGRESS'
export const SET_WALKTHROUGH_DATA = "SET_WALKTHROUGH_DATA"

// syllabus
export const SET_COURSE_DATA = 'SET_COURSE_DATA'
export const SET_ACCORDION_STATE = 'SET_ACCORDION_STATE'
export const SET_COURSE_CURRENT_STATUS = 'SET_COURSE_CURRENT_STATUS'
export const SET_COURSE_COMPLETED = 'SET_COURSE_COMPLETED'
export const SET_LEVEL_DATA = 'SET_LEVEL_DATA'

// dashboard
export const SET_CURRENT_LEARNING_DETAILS = 'SET_CURRENT_LEARNING_DETAILS';
export const USER_ACTIVITY_DETAILS = 'USER_ACTIVITY_DETAILS';
export const MODULE_NAMES = 'MODULE_NAMES';
export const SHOW_PRICE_POPUP = 'SHOW_PRICE_POPUP'
export const CHECK_PRICE_POPUP = 'CHECK_PRICE_POPUP'
export const TIMER_END = 'TIMER_END'
export const SHOW_PAYMENT = 'SHOW_PAYMENT'
export const UPDATE_FIREBASE_TOKEN = 'UPDATE_FIREBASE_TOKEN'
export const RENDER_TOUR = 'RENDER_TOUR';
export const CURRENT_STAGE_INDEX = 'CURRENT_STAGE_INDEX'
export const ANIMATION_CONTENT = 'ANIMATION_CONTENT'
export const PATHWAY_DRAWER = 'PATHWAY_DRAWER'
export const DAY_WEEK_PLANNER_MODAL = 'DAY_WEEK_PLANNER_MODAL'
export const FAIL_QUESTIONS = 'FAIL_QUESTIONS'

//day week plan
export const DAY_WEEK_CHECK = 'DAY_WEEK_CHECK'
export const SET_DAYWEEKPLAN = 'SET_DAYWEEKPLAN'
export const SET_DAYWEEKPLAN_TOGGLE = 'SET_DAYWEEKPLAN_TOGGLE'

// jobs
export const GET_JOBS = 'GET_JOBS';
export const REPORT_JOB = 'REPORT_JOB';

//bookmarks
export const SET_BOOKMARKS = 'SET_BOOKMARKS'

// blocked contents
export const SET_BLOCKED_CONTENTS = 'SET_BLOCKED_CONTENTS'

//user jobs
export const EDIT_USER_JOB = 'EDIT_USER_JOB';

// MODALS
export const MAJOR_MINOR_MODAL = 'MAJOR_MINOR_MODAL';
export const SNS_MODAL = 'SNS_MODAL';
export const WEB_SHARE_MODAL = 'WEB_SHARE_MODAL';
export const ACHIEVE_MODAL = 'ACHIEVE_MODAL';
export const CHECKOUT_MODAL = 'CHECKOUT_MODAL';
export const FRIEND_REFERRAL_MODAL = 'FRIEND_REFERRAL_MODAL'
export const WELCOME_MODAL = 'WELCOME_MODAL'
export const LEARNING_DONE_MODAL = 'LEARNING_DONE_MODAL'
export const LINK_MODAL = 'LINK_MODAL'
export const MENTORSHIP_MODAL = 'MENTORSHIP_MODAL'
export const VERSION_UPDATE_MODAL = 'VERSION_UPDATE_MODAL'
export const DAYS_BEHIND_MODAL = 'DAYS_BEHIND_MODAL'
export const SET_SUMMARY_MODAL = "SET_SUMMARY_MODAL"
//quiz
export const QUIZ_RESULTS = 'QUIZ_RESULTS';
export const QUIZ_SCORE = 'QUIZ_SCORE';

export const TIME_LEFT = 'TIME_LEFT'

// utm source
export const STORE_UTM_DETAILS = 'STORE_UTM_DETAILS'

//logs
export const NEW_VERSION_UPGRADE_USER = 'NEW_VERSION_UPGRADE_USER'

//logout
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'

// triggers
export const SET_RELATED_CONTENT_OVERLAY = 'SET_RELATED_CONTENT_OVERLAY'
export const SET_COMMUNITY_FLAG = 'SET_COMMUNITY_FLAG'
export const TOGGLE_LEARNINGPATH_DRAWER = 'TOGGLE_LEARNINGPATH_DRAWER'
export const TOGGLE_REFERRAL_MODAL = 'TOGGLE_REFERRAL_MODAL'
export const DISABLE_RELATED_CONTENT_TOOLTIP = 'DISABLE_RELATED_CONTENT_TOOLTIP'
export const SET_POPQUIZ_DATA = 'SET_POPQUIZ_DATA'
export const SET_COURSE_UPDATE_FLAG = 'SET_COURSE_UPDATE_FLAG'
export const SET_QUIZ_ATTEMPTED_FLAG = 'SET_QUIZ_ATTEMPTED_FLAG'
export const GO_TO_SYLLABUS = 'GO_TO_SYLLABUS'
export const SET_REVIEW_MODAL = 'SET_REVIEW_MODAL'
export const SHOW_TIMER_PANEL = 'SHOW_TIMER_PANEL'
export const SET_DASHBOARD_TIMESTAMP = 'SET_DASHBOARD_TIMESTAMP'
export const SET_REFERRAL_MODAL_TRIGGER = "SET_REFERRAL_MODAL_TRIGGER"
export const INCREASE_REFERRAL_MODAL_COUNT = 'INCREASE_REFERRAL_MODAL_COUNT';
export const TRIGGER_REFEERAL_MODAL_WALKTHROUGH = 'TRIGGER_REFEERAL_MODAL_WALKTHROUGH';
export const SET_REFERRED_USER_COUNT = 'SET_REFERRED_USER_COUNT';


//pricing page
export const PRICING_PAGE = 'PRICING_PAGE'

//content player
export const SET_CURRENT_CONTENT = 'SET_CURRENT_CONTENT'
export const SET_RELATED_CONTENT = 'SET_RELATED_CONTENT'
export const SHOW_POP = 'SHOW_POP'
export const SET_FRAME = 'SET_FRAME'
export const SET_COMPLETED = "SET_COMPLETED"
export const RESET_PLAYER = 'RESET_PLAYER'
export const SET_MODULES = 'SET_MODULES'
export const SET_PARENT = 'SET_PARENT'
export const SET_LEVEL_REFRESH = 'SET_LEVEL_REFRESH'
export const PREV_RESPONSE = 'PREV_RESPONSE'
export const SET_PREV_TASK_RESPONSE = 'SET_PREV_TASK_RESPONSE'
export const SET_TIME_PLAYED = 'SET_TIME_PLAYED'
export const SET_CONTENT_SUMMARY = 'SET_CONTENT_SUMMARY'

//notifications
export const NOTIFICATIONS = 'NOTIFICATIONS'

// exit intent
export const SET_EXIT_INTENT = "SET_EXIT_INTENT"
export const SET_LINKEDIN_SHARE_STATUS = "SET_LINKEDIN_SHARE_STATUS"
export const SET_TWITTER_SHARE_STATUS = "SET_TWITTER_SHARE_STATUS"
export const SET_YOUTUBE_SHARE_STATUS = "SET_YOUTUBE_SHARE_STATUS"
export const SET_GOOGLE_REVIEW_SHARE_STATUS = "SET_GOOGLE_REVIEW_SHARE_STATUS"
export const SET_MODAL_TYPE = 'SET_MODAL_TYPE'
export const SET_TRIAL_MODE = 'SET_TRIAL_MODE'
//five day
export const SET_CURRENT_FIVE_DAY_CONTENT = 'SET_CURRENT_FIVE_DAY_CONTENT'
export const SET_MARKED_ITEMS = 'SET_MARKED_ITEMS';
export const SET_SINGLE_MARKED_ITEM = 'SET_SINGLE_MARKED_ITEM';
