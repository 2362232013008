import { USER_TOKEN } from 'redux/types';
import { PURGE } from 'redux-persist';

export default (state = null, action) => {
	switch (action.type) {
		case PURGE:
			return null;
		case USER_TOKEN:
			return action.payload
		default:
			return state;
	}
}
