import { combineReducers } from 'redux';

// reducer files
import token from 'redux/reducers/authentication'
import onboarding from 'redux/reducers/onboarding'
import triggers from 'redux/reducers/triggers'
import jobs from 'redux/reducers/jobs'
import profile from 'redux/reducers/profile'
import course from 'redux/reducers/course'
import storage from 'redux-persist/lib/storage'
import player from 'redux/reducers/player'
import referral from 'redux/reducers/referral'
import fiveday from 'redux/reducers/fiveday'
import exitIntent from 'redux/reducers/exitIntent';
import * as serviceWorker from '../../serviceWorker';

import { LOGOUT_REQUEST } from '../types'

const appReducer = combineReducers({
	token,
	onboarding,
	jobs,
	triggers,
	profile,
	course,
	player,
	referral,
	fiveday,
	exitIntent,
});

const rootReducer = (state, action) => {
	if (action.type === LOGOUT_REQUEST) {
		storage.removeItem('persist:v2')
		serviceWorker.unregister()
		localStorage.clear()
		state = undefined;
	}
	return appReducer(state, action);
};

export default rootReducer;
