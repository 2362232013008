import React from 'react';
import Icon from '../utils/icon';
import '../styles/AdBlockModalContent.scss';

const AdBlockModalContent = ({ hadleAdBlockModalDissmised }) => {

	return (
		<div className="container adblock-bg">
			<div className="row justify-content-center">
				<div className="col-12">
					<div className="pt-4 row justify-content-center">
						<Icon icon="ICON_AD_BLOCK" className="col-8 adbloc-icon" />
					</div>
					<h1 className="adblock-heading"> Ad Blocker Detected!</h1>
					<div className="pb-4 row adblock-text justify-content-center">
						<div className="pb-4 col-12">
							We have noticed that you are using an Ad blocker.
							<br />
							We request you to turn it off.
							<br />
							Please be aware that our site is best experienced with Ad blockers turned
							off.
						</div>
						<button type="button" className="btn adblock-btn" onClick={hadleAdBlockModalDissmised}>
							I have disabled Ad blocking for this site
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AdBlockModalContent;
