import { axiosNoAuth } from 'config/axios-instances'
import { API_PAYMENT_LINK } from 'config/config'
import { Api_url } from 'config/api_url'

export function successPayment(data, successCallback, errorCallback) {
	return async function () {
		try {
			let response = await axiosNoAuth.post(API_PAYMENT_LINK + Api_url.SuccessPayment, data)
			successCallback && successCallback(response.data)
		} catch (e) {
			console.error(e)
			errorCallback && errorCallback(e.response.data.message)
		}
	}
}

export function getLinkDetails(data, successCallback, errorCallback) {
	return async function () {
		try {
			let response = await axiosNoAuth.post(API_PAYMENT_LINK + Api_url.GetLinkDetails, data)
			successCallback && successCallback(response.data)
		} catch (e) {
			console.error(e)
			errorCallback && errorCallback(e.response.data.message)
		}
	}
}

export function enableEMIOption(data, successCallback, errorCallback) {
	return async function () {
		try {
			let response = await axiosNoAuth.post(API_PAYMENT_LINK + Api_url.EmiEnable, data)
			successCallback && successCallback(response.data)
		} catch (e) {
			console.error(e)
			errorCallback && errorCallback(e.response.data.message)
		}
	}
}

export function getEMIPaymentLink(data, successCallback, errorCallback) {
	return async function () {
		try {
			let response = await axiosNoAuth.post(API_PAYMENT_LINK + Api_url.GetEmiDetails, data)
			successCallback && successCallback(response.data)
		} catch (e) {
			console.error(e)
			errorCallback && errorCallback(e.response.data.message)
		}
	}
}

export function emiPaymentSuccessful(data, successCallback, errorCallback) {
	return async function () {
		try {
			let response = await axiosNoAuth.post(API_PAYMENT_LINK + Api_url.EmiPaymentDone, data)
			successCallback && successCallback(response.data)
		} catch (e) {
			console.error(e)
			errorCallback && errorCallback(e.response.data.message)
		}
	}
}

export function getPricingPlanData(data, successCallback, errorCallback) {
	return async function () {
		try {
			let response = await axiosNoAuth.post(API_PAYMENT_LINK + Api_url.GetPlanData, data)
			successCallback && successCallback(response.data)
		} catch (e) {
			console.error(e)
			errorCallback && errorCallback(e.response.data.message)
		}
	}
}

export function enableFullPayment(data, successCallback, errorCallback) {
	return async function () {
		try {
			let response = await axiosNoAuth.post(API_PAYMENT_LINK + Api_url.FullPayment, data)
			successCallback && successCallback(response.data)
		} catch (e) {
			console.error(e)
			errorCallback && errorCallback(e.response.data.message)
		}
	}
}