import React from 'react'


/* Names with '_' represented as one level inside in folder structure */

// images url exports
export const IMAGES = {
  // icon_sleepy: { default: '/img/icon/sleepy.svg' },
  // icon_sad: { default: '/img/icon/sleepy.svg' },
  // icon_shocked: { default: '/img/icon/shocked.svg' },
  // icon_excited: { default: '/img/icon/excited.svg' },
  // icon_cool: { default: '/img/icon/cool.svg' },
  vector_promptHeader: { default: '/img/vector/promt-header.svg' },
  // avatar: { default: '/img/avatar.svg' },
  ceremonyImg: '/img/landing-ceremony-image.jpg',
  landingBgImg: '/img/landing-bg-image.jpg',
  vector_interviewPlan: '/img/vector/interview-plan.svg',
  vector_placementPlan: '/img/vector/placement-plan.svg',
  vector_learningPlan: '/img/vector/learning-plan.svg',
  modalHeader: '/img/modal-header.svg',
  defaultCoverImg: '/img/default-cover-image.jpg',
}


// SVG IMPORTS
const IMG_URL = {
  vector_assessmentCard: { default: '/img/vector/assessment-card.svg' },
  feat_google: { default: '/img/feat/google.svg' },
  icon_blueDot: { default: '/img/icon/blue-dot.svg' },
  learningHeader: { default: '/img/learning-header.svg' },
  interviewHeader: { default: '/img/interview-header.svg' },
  placementHeader: { default: '/img/placement-header.svg' },
  blakLockIcon: { default: '/img/black-lock-icon.svg' },
  vector_pricingBottom: { default: '/img/vector/pricing-page-bottom.svg' },
  vector_pricingTop: { default: '/img/vector/pricing-page-top.svg' },
  icon_circularGreenTick: { default: '/img/icon/circular-green-tick.svg' },
  vector_signupCorousel: { default: '/img/vector/signup-corousel.svg' },
  icon_superman: { default: '/img/icon/superman.svg' },
  icon_blackGlass: { default: '/img/icon/black-glass.svg' },
  icon_confused: { default: '/img/icon/confused.svg' },
  icon_nerd: { default: '/img/icon/nerd.svg' },
  icon_poop: { default: '/img/icon/poop.svg' },
  icon_scholarship: { default: '/img/icon/scholarship.svg' },
  icon_addFriend: { default: '/img/icon/add-friend.svg' },
  icon_workReadiness: { default: '/img/icon/work-readiness.svg' },
  icon_lessthan: { default: '/img/icon/lessthan-symbol.svg' },
  icon_downloadWhite: { default: '/img/icon/download-icon-white.svg' },
  vector_levelPageBg: { default: '/img/vector/level-page-background.svg' },
  ratingExpansion: { default: '/img/icon/fullscreen.svg' },
  smile_smiley: { default: '/img/emoji.svg' },
  link: { default: '/img/link.svg' },
  cross: { default: '/img/cross-review.svg' },
  puzzle: { default: '/img/puzzle.svg' },
  correct: { default: '/img/correct.svg' },
  wrong: { default: '/img/false.svg' },
  dottedCircle: { default: '/img/dotted-circle.svg' },
  tooSlowGif: { default: '/img/too-slow.gif' },
  youGotThis: { default: '/img/got-this.gif' },
  doBetter: { default: '/img/do-better.gif' },


  // PNG, JPG, JPEG IMPORTS
  icon_hourGlass: { default: '/img/icon/hourglass.png' },
  poppers: { default: '/img/poppers.jpeg' },
  benefits_f2f: { default: '/img/benefits/f2f.png' },
  benefits_personalized: { default: '/img/benefits/personalized.png' },
  benefits_crown: { default: '/img/benefits/crown.png' },
  benefits_curriculum: { default: '/img/benefits/curriculum.png' },
  benefits_strategy: { default: '/img/benefits/strategy.png' },
  benefits_dir: { default: '/img/benefits/dir.png' },
  career_AccountManager: { default: '/img/career/AccountManager.jpg' },
  career_SocialMedia: { default: '/img/career/SocialMedia.jpg' },
  career_ContentMarketing: { default: '/img/career/ContentMarketing.jpg' },
  career_ppc: { default: '/img/career/ppc.jpg' },
  career_SEOSpecial: { default: '/img/career/SEOSpecial.jpg' },
  questionMarkImage: { default: '/img/question-mark.png' },
  foundationComplete: { default: '/img/foundation.png' },
  whatsappImg: { default: '/img/whatsapp.png' },
  notification_calender: { default: '/img/calendar.png' },

  test_siddharth: { default: '/img/test/siddharth.jpeg' },
  test_ashana: { default: '/img/test/ashana.jpg' },
  test_aadil: { default: '/img/test/aadil.jpeg' },
  test_adityaTaneja: { default: '/img/test/aditya-taneja.jpeg' },
  test_amitJude: { default: '/img/test/amit-jude.jpeg' },
  test_anjali: { default: '/img/test/anjali-shaw.jpg' },
  test_hitakshi: { default: '/img/test/hitakshi.jpeg' },
  test_rodhan: { default: '/img/test/roshan.jpeg' },
  test_shubham: { default: '/img/test/shubham-das.jpg' },
  test_mohit: { default: '/img/test/mohit-suryavanshi.png' },
  test_silviya: { default: '/img/test/silviya-gomes.jpeg' },
  test_abhijith: { default: '/img/test/abhijith-jk.jpeg' },
  test_manojKumar: { default: '/img/test/manoj-kumar.jpeg' },
  test_abdulBasith: { default: '/img/test/abdul-basith.jpg' },
  test_yashika: { default: '/img/test/yashika-zutshi.jpeg' },
  test_snehaParve: { default: '/img/test/sneha-parve.jpeg' },
  test_bhartendu: { default: '/img/test/bhartendu-prakash.jpg' },
  test_apekshaJain: { default: '/img/test/apeksha-jain.jpeg' },
  test_nehaK: { default: '/img/test/neha-k.png' },
  test_ankitDubey: { default: '/img/test/ankit-dubey.jpeg' },

  mentor_sidh: { default: '/img/mentor/sidh.jpg' },
  mentor_arjun: { default: '/img/mentor/arjun.jpg' },
  mentor_manish: { default: '/img/mentor/manish.png' },
  mentor_prajakta: { default: '/img/mentor/prajakta.jpg' },
  mentor_sreshtha: { default: '/img/mentor/sreshtha.jpg' },
  mentor_rahul: { default: '/img/mentor/rahul-poojari.jpg' },
  mentor_vatsal: { default: '/img/mentor/vatsal-vora.jpg' },
  mentor_saurabh: { default: '/img/mentor/saurabh-bandiwadekar.jpg' },
  mentor_vijayMayekar: { default: '/img/mentor/vijay-mayekar.jpg' },
  mentor_vijayThakur: { default: '/img/mentor/vijay-thakur.jpg' },
  mentor_rinkalTalsania: { default: '/img/mentor/rinkal-talsania.jpg' },
  mentor_anubhavAmbastha: { default: '/img/mentor/anubhav-ambastha.jpg' },
  mentor_supriyaVs: { default: '/img/mentor/supriya-vs.jpeg' },

  learner_amogh: { default: '/img/learner/amogh-rao.png' },
  learner_nikita: { default: '/img/learner/nikita-bhargava.png' },
  learner_rishab: { default: '/img/learner/rishab-mishra.png' },
  learner_rydel: { default: '/img/learner/rydel-dcosta.png' },
  learner_shreya: { default: '/img/learner/shreya.png' },
  learner_vishwas: { default: '/img/learner/vishwas.png' },
  learner_vivek: { default: '/img/learner/vivek-singh.png' },

  feat_facebook: { default: '/img/feat/facebook.jpg' },
  feat_buffer: { default: '/img/feat/buffer.jpg' },
  feat_forbes: { default: '/img/feat/forbes.jpg' },
  feat_hootsuite: { default: '/img/feat/hootsuite.jpg' },
  feat_hubspot: { default: '/img/feat/hubspot.jpg' },
  feat_bookmyshow: { default: '/img/feat/bookmyshow.png' },
  feat_neilpatel: { default: '/img/feat/neilpatel.jpg' },
  feat_schbang: { default: '/img/feat/schbang.jpg' },

  topic_analytics: { default: '/img/topic/analytics.jpg' },
  topic_brandDesign: { default: '/img/topic/brand-design.jpg' },
  topic_contentMarketing: { default: '/img/topic/content-marketing.jpg' },
  topic_client: { default: '/img/topic/client.jpg' },
  topic_customer: { default: '/img/topic/customer.jpg' },
  topic_ecommerce: { default: '/img/topic/ecommerce.jpg' },
  topic_growth: { default: '/img/topic/growth.jpg' },
  topic_seo: { default: '/img/topic/seo.jpg' },
  topic_sem: { default: '/img/topic/sem.jpg' },
  topic_email: { default: '/img/topic/email.jpg' },
  topic_influencerMarketing: { default: '/img/topic/influencer-marketing.jpg' },
  topic_marketingFundamentals: { default: '/img/topic/marketing-fundamentals.jpg' },
  topic_mediaPlanning: { default: '/img/topic/media-planning.jpg' },
  topic_mobileMarketing: { default: '/img/topic/mobile-marketing.jpg' },
  topic_socialMediaMarketing: { default: '/img/topic/social-media-marketing.jpg' },
  badge: { default: '/img/icon/badge.png' },
  // icon_badgeFire{default:: '/img/icon/badge-fire.png'},
  // icon_greenFlag{default:: '/img/icon/green-flag.png'},
  vector_article: { default: '/img/vector/article.png' },
  vector_video: { default: '/img/vector/video.png' },
  vector_audio: { default: '/img/vector/audio.png' },
  vector_book: { default: '/img/vector/book.png' },
  vector_premiumTag: { default: '/img/vector/premium-tag.png' },
  icon_starEmpty: { default: '/img/icon/star-empty.png' },
  icon_starHalf: { default: '/img/icon/star-half.png' },
  icon_starFilled: { default: '/img/icon/star-filled.png' },
  icon_facebookGrey: { default: '/img/icon/facebook-icon-grey.png' },
  icon_linkedinGrey: { default: '/img/icon/linkedin-icon-grey.png' },
  icon_twitterGrey: { default: '/img/icon/twitter-icon-grey.png' },
  icon_othersGrey: { default: '/img/icon/others-icon-grey.png' },
  icon_calendar: { default: '/img/icon/calendar.png' },
  careerninjaWhiteLogo: { default: '/img/careerninja-white-logo.png' },
  icon_clipboard: { default: '/img/icon/clipboard.jpg' },
  icon_logout: { default: '/img/icon/logout.png' },
  icon_facebookWhite: { default: '/img/icon/facebook.png' },
  icon_linkedinWhite: { default: '/img/icon/linkedin.png' },
  icon_twitterWhite: { default: '/img/icon/twitter.png' },
  icon_otherWebsiteWhite: { default: '/img/icon/other-website.png' },
  referralShareImg: { default: '/img/referral-share.png' },
  icon_onboardingCompetence: { default: '/img/icon/onboarding-competence.png' },
  icon_onboardingFreelance: { default: '/img/icon/onboarding-freelance.png' },
  icon_onboardingImpression: { default: '/img/icon/onboarding-impression.png' },
  icon_onboardingJobSeeking: { default: '/img/icon/onboarding-jobSeeking.png' },
  icon_onboardingProfit: { default: '/img/icon/onboarding-profit.png' },
  icon_onboardingPromotion: { default: '/img/icon/onboarding-promotion.png' },
  dmOverviewDesktop: { default: '/img/DM-overview-desktop.png' },
  dmOverviewMobile: { default: '/img/DM-overview-mobile.png' },
  company_dentsu: { default: '/img/company/dentsu.png' },
  company_foxymoron: { default: '/img/company/foxymoron.png' },
  company_jwt: { default: '/img/company/jwt.png' },
  company_glitch: { default: '/img/company/glitch.png' },
  company_kinnect: { default: '/img/company/kinnect.png' },
  company_lk: { default: '/img/company/l&k.png' },
  company_mindshift: { default: '/img/company/mindshift.png' },
  company_schbang: { default: '/img/company/schbang.png' },
  company_wat: { default: '/img/company/wat.png' },
  company_shronitSign: { default: '/img/company/shronit-sign.png' },
  vector_CircularBubble: { default: '/img/vector/circular-bubble.png' },
  careerninjaWoTagline: { default: '/img/careerninja-wo-tagline.png' },
  careerninjaWhiteWoTagline: { default: '/img/careerninja-white-wo-tagline.png' },
  icon_deleteBtn: { default: '/img/icon/delete-button.png' },
  icon_skillsTick: { default: '/img/icon/skills-tick.png' },
  cnMascot: { default: '/img/cn-mascot.jpg' },
  mascotPng: { default: '/img/CN_Mascot.png' },
  progressSignup: { default: '/img/progress-signup.png' },
  progressAssessment: { default: '/img/progress-assessment.png' },
  progressProfile: { default: '/img/progress-profile.png' },
  progressStreak: { default: '/img/progress-streak.png' },
  jobVector: { default: '/img/jobs-vector.svg' },
  vector_accordionDiscovery: { default: '/img/vector/accordion-discovery.png' },
  vector_accordionLearning: { default: '/img/vector/accordion-learning.png' },
  vector_accordionAchieve: { default: '/img/vector/accordion-achieve.png' },
  verified_tick: { default: '/img/icon/verified.png' },
  shield: { default: '/img/icon/shield.png' },
  google_logo: { default: '/img/icon/google-logo.png' },
  star_icon: { default: '/img/icon/star-icon.png' },
  sad_smiley: { default: '/img/sad-smiley.png' },
  arrowUp: { default: '/img/arrow-up.png' },
  settings: { default: '/img/settings.png' },
  time: { default: '/img/time.png' },
  closeBtn: { default: '/img/closebtn.png' },
  crossBtn: { default: '/img/crossbtn.png' },
  trashCan: { default: '/img/trash-can.png' },
  notifyCorrect: { default: '/img/notify-correct.png' },
  notifyTimer: { default: '/img/notify-timer.png' },
  notifyTreasure: { default: '/img/notify-treasure.png' },
  notifySpeaker: { default: '/img/notify-speaker.png' },


  // defaultContentImg: { default: '/img/content-default.jpg' },

  // GIF, WEBP IMPORTS
  rocketLoader: { default: '/img/rocket-loader.gif', webp: '/img/rocket-loader.webp' },
  errorGif: { default: '/img/error.gif', webp: '/img/error.webp' },
  discountOn: { default: '/img/discount-on.gif', webp: '/img/discount-on.webp' },
  discountOff: { default: '/img/discount-off.gif', webp: '/img/discount-off.webp' },
  loadingBlocks: { default: '/img/rocket-loader.gif', webp: '/img/loading-blocks.webp' },
  priceModalGif: { default: '/img/price-modal.gif', webp: '/img/price-modal.webp' },
  referGif: { default: '/img/refer.gif', webp: '/img/refer.webp' },
  firstWeekGif: { default: '/img/first-week.gif', webp: '/img/first-week.webp' },
  secondWeekGif: { default: '/img/second-week.gif', webp: '/img/second-week.webp' },
  reviseCardGif: { default: '/img/revise-gif.gif', webp: '/img/revise-gif.webp' },
  waitingGif: { default: '/img/waiting.gif', webp: '/img/waiting.webp' },
  getStarted: { default: '/img/started.gif', webp: '/img/started.webp' },
  thirdDay: { default: '/img/third-day.gif', webp: '/img/third-day.webp' },
  lastWeek: { default: '/img/last-week.gif', webp: '/img/last-week.webp' },
  learningComplete: { default: '/img/learning-completed.gif', webp: '/img/learning-completed.webp' },
  newVersionGif: { default: '/img/version-upgrade.gif', webp: '/img/version-upgrade.webp' },
  courseCompleted: { default: '/img/course-completed.gif', webp: '/img/course-completed.webp' },
  mobilePwaInstall_s1: { default: '/img/mobile-pwa-s1.gif', webp: '/img/mobile-pwa-s1.webp' },
  mobilePwaInstall_s2: { default: '/img/mobile-pwa-s2.gif', webp: '/img/mobile-pwa-s2.webp' },
  guarantee_img: { default: '/img/icon/hundred.webp', webp: '/img/icon/hundred.webp' },
  questionRelated: { default: '/img/question-related.gif' },
  bulb: { default: '/img/bulb.gif' },
  popupCorrect: { default: "/img/correct.gif" },
  popupWrong: { default: "/img/wrong.gif" }
}


export class ImageLoader extends React.Component {

  getUrl = (src, key) => {
    return IMG_URL[src].hasOwnProperty(key) ? IMG_URL[src][key] : undefined
  }

  render = () => {
    let alt = this.props.alt ? this.props.alt : "";
    return (
      <picture>
        {this.getUrl(this.props.src, 'webp') && this.getUrl(this.props.src, 'webp') !== undefined && <source srcSet={this.getUrl(this.props.src, 'webp')} type="image/webp" className={this.props.className} style={this.props.style} height={this.props.height ? this.props.height : undefined} width={this.props.width ? this.props.width : undefined} id={this.props.id ? this.props.id : undefined} />}
        <img className={this.props.className} style={this.props.style} src={this.getUrl(this.props.src, 'default')} alt={alt} height={this.props.height ? this.props.height : undefined} width={this.props.width ? this.props.width : undefined} onClick={this.props.onClick} id={this.props.id ? this.props.id : undefined} />
      </picture>
    )
  }

}
