import { PURGE } from 'redux-persist';
import { STORE_REFERRAL } from '../types';

export default (state = null, action) => {
	switch (action.type) {
		case PURGE:
			return null;
		case STORE_REFERRAL:
			return action.payload
		default:
			return state;
	}
}
