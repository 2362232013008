import {
    SET_RELATED_CONTENT_OVERLAY, TOGGLE_CERT_MODAL, TOGGLE_CV_MODAL, DISABLE_RELATED_CONTENT_TOOLTIP, SET_ACCORDION_STATE,
    ANIMATION_CONTENT, SET_COMMUNITY_FLAG, TOGGLE_LEARNINGPATH_DRAWER, UPDATE_FIREBASE_TOKEN, TOGGLE_REFERRAL_MODAL, ACHIEVE_MODAL, SET_DASHBOARD_TIMESTAMP,
    MAJOR_MINOR_MODAL, SNS_MODAL, DAY_WEEK_PLANNER_MODAL, SET_POPQUIZ_DATA, SET_COURSE_UPDATE_FLAG, GO_TO_SYLLABUS, SHOW_TIMER_PANEL, SET_REVIEW_MODAL, 
    SET_SUMMARY_MODAL,SET_REFERRAL_MODAL_TRIGGER,INCREASE_REFERRAL_MODAL_COUNT, TRIGGER_REFEERAL_MODAL_WALKTHROUGH, SET_REFERRED_USER_COUNT
} from '../types'
import { PURGE,REHYDRATE } from 'redux-persist'
export default (state = { updateFirebaseToken: 1 }, action) => {
    switch (action.type) {
        case PURGE:
            return null
        case REHYDRATE:
            return {
                ...state, referral_modal_count: 0
            }
        case SET_RELATED_CONTENT_OVERLAY:
            return {
                ...state,
                rc_overlay: action.payload
            }

        case TOGGLE_CERT_MODAL:
            return {
                ...state,
                showCVPrompt: false,
                showCertPrompt: action.payload
            }

        case TOGGLE_CV_MODAL:
            return {
                ...state,
                showCVPrompt: action.payload,
                showCertPrompt: false
            }

        case GO_TO_SYLLABUS:
            return {
                ...state,
                syllabus: action.payload
            }

        case TOGGLE_LEARNINGPATH_DRAWER:
            return {
                ...state,
                lpDrawer: action.payload
            }

        case ANIMATION_CONTENT:
            return {
                ...state,
                animationContentCard: action.payload
            }

        case SET_COMMUNITY_FLAG:
            return {
                ...state,
                community_loggedIn: action.payload
            }

        case UPDATE_FIREBASE_TOKEN:
            return {
                ...state,
                updateFirebaseToken: 0
            }

        case TOGGLE_REFERRAL_MODAL:
            return {
                ...state,
                referralModal: action.payload
            }

        case ACHIEVE_MODAL:
            return {
                ...state,
                achieveModal: action.payload
            }

        case MAJOR_MINOR_MODAL:
            return {
                ...state,
                majorModalOpen: action.payload
            }

        case DISABLE_RELATED_CONTENT_TOOLTIP:
            return {
                ...state,
                disableRcTooltip: action.payload
            }

        case DAY_WEEK_PLANNER_MODAL:
            return {
                ...state,
                dayWeekPlannerModal: action.payload
            }

        case SET_POPQUIZ_DATA:
            return {
                ...state,
                popquiz: action.payload
            }

        case SET_COURSE_UPDATE_FLAG:
            return {
                ...state,
                update_course_data: action.payload
            }

        case SET_ACCORDION_STATE:
            return {
                ...state,
                accordionState: action.payload
            }
        case SHOW_TIMER_PANEL:
            return {
                ...state,
                showTimerPanel: action.payload
            }
        case SET_DASHBOARD_TIMESTAMP:
            return {
                ...state,
                dashboard_timestamp: action.payload
            }

        case SET_REVIEW_MODAL:
            return {
                ...state,
                review_modal: action.payload
            }
        case SET_SUMMARY_MODAL:
            return {
                ...state,
                summary_modal: action.payload
            }
        case SET_REFERRAL_MODAL_TRIGGER:
            if(state.referral_modal_count){
                return state
            }
            return{
                ...state,referral_modal_count: 0
            }
        case INCREASE_REFERRAL_MODAL_COUNT:
            return {
				...state,
				referral_modal_count: state.referral_modal_count + action.payload,
			};
        case TRIGGER_REFEERAL_MODAL_WALKTHROUGH:
            if(state.trigger_referral_walkthrough === undefined){
                return {
                    ...state,trigger_referral_walkthrough: false
                }
            }
            return {
                ...state,trigger_referral_walkthrough: action.payload
            }
        case SET_REFERRED_USER_COUNT:
            return {
                ...state, referred_user_count: action.payload
            } 
        default:
            return state;
    }
};
